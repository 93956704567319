<template>
	<div class="card card-custom card-stretch gutter-b">
		<div class="card-header border-0 align-items-center">
			<h3 class="card-title font-weight-bolder text-dark">
				{{ name }}
				<a
					v-if="['admin', 'noc', 'staff'].includes(currentUser.role)"
					target="_blank"
					:href="`https://orionweb2017.itcglobal.com/Orion/Interfaces/InterfaceDetails.aspx?NetObject=I:${interfaceID}&view=InterfaceDetails`"
					v-b-tooltip="'Open in Solarwinds'"
				>
					<img class="mx-5" width="20" :src="'/media/logos/solarwinds.png'" />
				</a>
			</h3>
			<div>
				<b-button v-if="showDownload" variant="outline-primary" size="sm" @click="downloadTotalBW"
					>Download CSV</b-button
				>
			</div>
		</div>
		<div class="card-body pt-2">
			<div v-if="dyData.length == 0 && loaded">
				<h4>
					<i
						class="fa fa-exclamation-circle fa-circle"
						aria-hidden="true"
						style="
							color: red;
							background-image: radial-gradient(at center, white 40%, transparent 40%);
						"
					></i>
					This site has no data available for the selected range of time.
				</h4>
			</div>
			<dygraphs v-if="dyData.length > 0" :data="dyData" :options="dyOpts" />
		</div>
	</div>
</template>

<script>
import { downloadCSV } from '@/helpers';
const date = new Date();
const offset = date.getTimezoneOffset() * 60000;
import { KMG } from '@/helpers';
import { mapGetters } from 'vuex';
import Dygraph from 'dygraphs';

const darkenColor = (colorStr) => {
	// Defined in dygraph-utils.js
	var color = Dygraph.toRGB_(colorStr);
	color.r = Math.floor((255 + color.r) / 2);
	color.g = Math.floor((255 + color.g) / 2);
	color.b = Math.floor((255 + color.b) / 2);
	return 'rgb(' + color.r + ',' + color.g + ',' + color.b + ')';
};

const barChartPlotter = (e) => {
	var ctx = e.drawingContext;
	var points = e.points;
	var y_bottom = e.dygraph.toDomYCoord(0);

	ctx.fillStyle = darkenColor(e.color);

	// Find the minimum separation between x-values.
	// This determines the bar width.
	var min_sep = Infinity;
	for (var i = 1; i < points.length; i++) {
		var sep = points[i].canvasx - points[i - 1].canvasx;
		if (sep < min_sep) min_sep = sep;
	}
	var bar_width = Math.floor((2.0 / 3) * min_sep);

	// Do the actual plotting.
	for (var i = 0; i < points.length; i++) {
		var p = points[i];
		var center_x = p.canvasx;

		ctx.fillRect(center_x - bar_width / 2, p.canvasy, bar_width, y_bottom - p.canvasy);

		ctx.strokeRect(center_x - bar_width / 2, p.canvasy, bar_width, y_bottom - p.canvasy);
	}
};

export default {
	name: 'SolarwindsGraphs',
	components: {
		dygraphs: () => import('@/view/content/lib/dygraphs.vue'),
	},
	props: {
		name: { Type: String, required: true },
		id: {
			type: Number,
			required: true,
		},
		properties: { type: Object, require: false },
		device: {
			type: Object,
			require: false,
		},
		updated: {
			type: Number,
		},
	},
	data() {
		return {
			dyData: [],
			dyOpts: {},
			showDownload: false,
			loaded: false,
		};
	},
	computed: {
		...mapGetters(['currentUser']),
		interfaceID() {
			return this.device.Source.match(/\d+/g)[1];
		},
	},
	methods: {
		load() {
			this.loaded = false;
			var intstr = '';
			if (this.properties) {
				if (this.properties.interfacestotal) {
					Object.keys(this.properties.interfacestotal).forEach((it) => {
						if (this.properties.interfacestotal[it]) {
							if (intstr != '') {
								intstr = intstr + ',';
							}
							intstr = intstr + it;
						}
					});
					intstr = '?interfacestotal=' + intstr;
				}
			}

			this.$http.get(`solarwinds/${this.id}${intstr}`).then((resp) => {
				var data = resp.data.data;
				if (this.name == 'MS1 Total BW') {
					console.log(data);
				}

				////
				/// ***********************
				///        CPU LOAD
				/// ***********************
				///
				if (data.InterfaceName == 'CPU Load') {
					this.dyOpts = {
						labels: ['Timestamp', 'CPU Usage', 'Memory Usage'],
						labelsKMG2: false,
						labelsSeparateLines: false,
						includeZero: true,
						strokeWidth: 1.5,
						fillGraph: true,
						fillAlpha: 0.06,
						series: {
							'Memory Usage': {
								color: '#54a5ff',
							},
						},
						valueRange: [0, 100],
						connectSeparatedPoints: true,
						axes: {
							y: {
								valueFormatter: function (v) {
									return v + '%';
								},
							},
						},
					};
					var gd = [];

					Object.keys(data.data).forEach((ts) => {
						gd.push([
							new Date(ts * 1000 + offset),
							data.data[ts].AvgLoad,
							data.data[ts].AvgPercentMemoryUsed,
						]);
					});
					this.dyData = gd;
				}
				////
				/// ***********************
				///        RESPONSE TIME
				/// ***********************
				///
				else if (data.InterfaceName == 'Response Time') {
					var min = 550;
					var gd = [];
					// HANDLE DEVICE OPTION FOR LATENCY OFFSET
					var rttOffset = this.device.properties?.latencyoffset || 0;

					// round to hourly
					var grouped = {};
					Object.keys(data.data).forEach((ts) => {
						var hour = ts - (ts % 3600);
						if (grouped[hour]) {
							if (data.data[ts].AvgResponseTime != 0 && data.data[ts].AvgResponseTime != null) {
								grouped[hour].rtt += data.data[ts].AvgResponseTime;
								grouped[hour].rqty++;
							}
							grouped[hour].loss += data.data[ts].PercentLoss;
							grouped[hour].lqty++;
						} else {
							grouped[hour] = {
								rtt: data.data[ts].AvgResponseTime,
								rqty: 1,
								loss: data.data[ts].PercentLoss,
								lqty: 1,
							};
						}
					});
					Object.keys(grouped).forEach((ts) => {
						var rt = grouped[ts].rtt / grouped[ts].rqty;
						rt = rt - rttOffset;
						if (rt > 0 && rt < min) {
							min = rt;
						}
						gd.push([
							new Date(ts * 1000 + offset),
							Math.round(rt),
							Math.round((grouped[ts].loss / grouped[ts].lqty) * 10) / 10,
						]);
					});

					this.dyData = gd;

					this.dyOpts = {
						labels: ['Timestamp', 'Latency', 'Packet Loss'],

						ylabel: 'Average Latency (ms)',
						y2label: 'Average Packet Loss',
						labelsKMG2: false,
						labelsSeparateLines: false,
						includeZero: true,
						strokeWidth: 1.5,
						fillGraph: true,
						fillAlpha: 0.06,
						valueRange: [0, null],
						connectSeparatedPoints: true,
					};
					this.dyOpts.series = {
						'Packet Loss': {
							axis: 'y2',
							color: '#ff0000',
							strokeWidth: 2,
						},
						Latency: {
							plotter: barChartPlotter,
						},
					};
					this.dyOpts.axes = {
						y: {
							valueRange: [0, null],
							valueFormatter: function (v, o, s) {
								if (s == 'Latency') {
									return v + 'ms';
								}
								return null;
							},
						},
						y2: {
							valueRange: [null, 100],
							axisLabelFormatter: function (y) {
								return Math.round(y) + '%';
							},
							valueFormatter: function (v, o, s) {
								if (s == 'Packet Loss') {
									return v + '%';
								}
								return null;
							},
						},
					};
				}
				////
				/// ***********************
				///        TRAFFIC
				/// ***********************
				///
				else {
					this.showDownload = true;
					var labels = ['Timestamp', 'Out', 'In'];
					if (this.properties?.showminlegend || this.properties?.showmingraph) {
						labels.push('Min Out');
						labels.push('Min In');
					}
					if (this.properties?.showmaxlegend || this.properties?.showmaxgraph) {
						labels.push('Max Out');
						labels.push('Max In');
					}

					var gd = [];
					Object.keys(data.data).forEach((ts) => {
						var de1 = [new Date(ts * 1000 + offset), data.data[ts].out, data.data[ts].ind];
						if (this.properties?.showminlegend || this.properties?.showmingraph) {
							de1.push(data.data[ts].minout);
							de1.push(data.data[ts].minind);
						}
						if (this.properties?.showmaxlegend || this.properties?.showmaxgraph) {
							de1.push(data.data[ts].maxout);
							de1.push(data.data[ts].maxind);
						}
						gd.push(de1);
					});
					this.dyData = gd;
					var series = {};
					if (this.properties?.showminlegend || !this.properties?.showmingraph) {
						series['Min Out'] = { strokeWidth: 0 };
						series['Min In'] = { strokeWidth: 0 };
					}
					if (this.properties?.showmaxlegend || !this.properties?.showmaxgraph) {
						series['Max Out'] = { strokeWidth: 0 };
						series['Max In'] = { strokeWidth: 0 };
					}
					this.dyOpts = {
						labels: labels,
						labelsKMG2: false,
						labelsSeparateLines: false,
						fillGraph:
							this.properties?.showmingraph || this.properties?.showmaxgraph ? false : true,
						strokeWidth: 1.5,
						fillAlpha: 0.06,
						series: {
							Out: {
								color: '#54a5ff',
							},
						},
						axes: {
							y: {
								valueFormatter: function (y) {
									return KMG(y) + 'bps';
								},
								axisLabelFormatter: function (y) {
									return KMG(y);
								},
							},
						},
						includeZero: true,
						connectSeparatedPoints: true,
						series: series,
					};
					this.loaded = true;
				}
			});
		},
		downloadTotalBW() {
			let csv = this.dyOpts.labels.join(',') + '\n';
			this.dyData.forEach((row) => {
				csv += row.join(',') + '\n';
			});
			downloadCSV(csv, this.name + new Date().toISOString().split('T')[0] + '.csv');
		},
	},
	watch: {
		updated() {
			this.load();
		},
	},
	created() {
		this.load();
	},
};
</script>

<style></style>
